import React, { useContext } from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import SummerKindnessModalContent from "@src/components/partials/summer-kindness-series/SummerKindnessModalContent";
import window from "global/window";

function SummerKindnessSeriesWeek1() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "summer-header" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "chat-circle" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity2: file(name: { eq: "thinking-circle" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1, activity2 } = data;
  const modalContext = useContext(ModalContext);

  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 1"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 1",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="Reading Emotions"
        headerSub="Kindness Habit 1:"
        headerText={
          <>
            Recognize and value the
            <br /> feelings and needs of others
          </>
        }
        headerImage={headerImage}
        whatisIt="The ability to read emotions is a superpower and gives us a window into how others are thinking and feeling. No two people are the same! This habit requires curiosity and observation to discover more about someone else. It’s a helpful skill for kids to practice when they meet someone new. It's also a great habit for anyone to better understand the people they already know — or think they know!"
        whyIsItImportant="Before kids can understand how to be kind to others, they need to first tune into the moods and feelings of others. Kids enjoy clues that help them discover these emotions and expressions. Some feelings are easy (happy or sad), some can be tricky (anxious), and some are just plain confusing (is he tired or angry?)."
        habbits={[
          {
            header: "Encourage Face-to-Face:",
            text: "Eye contact is a master key to people’s emotions and feelings. Quiz kids about the color of a talker’s eyes to get them in the habit.",
          },
          {
            header: "Show Curiosity About Others:",
            text: "Why do you think that woman is frowning? Why are those boys snickering? Wondering aloud about other people or favorite characters can expand a kid’s curiosity. Ask questions to know how kids are understanding what they see and hear, and to help them out when they’re confused.",
          },
          {
            header: "Label Feelings and Gestures:",
            text: "You look worried. I am frustrated. I see your shoulders are slumped, are you tired? Label feelings out loud and match emotions with gestures. This helps kids see the message they are sending about their feelings, and what clues to look for in others.",
          },
          {
            header: "Encourage Caring Action:",
            text: "Take the extra step and ask: What do you think would make them feel better? Is there anything we can do to help?",
          },
        ]}
        activitiesText="Practice Reading Emotions at home :) These are ideas to inspire new traditions. Did you know when kids are asked to teach it makes ideas more
              memorable?"
        activities={[
          {
            icon: activity1,
            header: "Feelings Charades",
            text: "Power-up your ability to read emotions!",
            onClick: () => {
              modalContext.showModal(ModalType.SummerKindnessModal, {
                content: (
                  <SummerKindnessModalContent
                    header1="Feeling Charades"
                    text1="A game of feeling charades is a fun and interactive way to power-up our ability to read other's emotions! Watch the Feelings Charades video for instructions!"
                    text2="Activity Type: Craft & Create, Family Time Play Time: 45 min to prep and play!"
                    header2="Reading Emotions"
                    items={[
                      { youtubeUrl: "https://www.youtube.com/embed/BQMV-mfuNzY" },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/Week1_FeelingsCharades.pdf",
                        text: "Activity PDF",
                        icon: "pdf",
                      },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/Feelings_Charades_Card_Pack.pdf",
                        text: "Feeling Charades cards PDF",
                        icon: "template",
                      },
                    ]}
                  />
                ),
              });
            },
          },
          {
            icon: activity2,
            header: "Kindness Creation Challenge",
            text: "Do you have an idea for a new way to practice Reading Emotions? Adults, submit your family’s ideas for a chance to share it with the world!",
            onClick: () => {
              window.location.href = "https://www.surveymonkey.com/r/kindnesscreation";
            },
          },
        ]}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek1;
